/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"

import Header, { Logo } from "./header"
import "./scss/layout.scss"

// assets
import Facebook from "../images/facebook-icon.svg"
import LinkedIn from "../images/linked-in-logo.svg"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const [menuState, setMenuState] = useState(false)

  const toggleMenuState = () => {
    setMenuState(!menuState)
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: menuState ? "overflow-hidden" : "",
        }}
      />
      <div className="site-wrapper">
        <Header toggleMenuState={toggleMenuState} menuState={menuState} />
        <main>{children}</main>
        <footer className="site-footer">
          <header>
            <Logo />
            <div>
              <p>
                1-58/5/A, 3rd Floor, Above Bank of India, <br></br> Near
                Opp.Furniture City, Gachibowli X roads, <br></br>Hyderabad, Telangana - 500031.
                <br></br>
                <br></br>
                <a href="mailto:info@prasthana.com">info@prasthana.com</a>
              </p>
            </div>
          </header>
          <hr />
          <div>
            © Prasthana Software Solutions {new Date().getFullYear()}
            <br />
            <div>
              <a
                rel="noreferrer"
                href="https://www.facebook.com/prasthanasoftwaresolutions/"
                target="_blank"
              >
                <Facebook />
              </a>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/company/prasthana-software-solutions-pvt-ltd/"
                target="_blank"
              >
                <LinkedIn />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
